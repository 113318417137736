import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UtilityService } from '../shared/services/utility.service';
import { MatDialogRef } from '@angular/material/dialog';


@Component({
    selector: 'app-googlemeet-report',
    templateUrl: './googlemeet-report.component.html',
    styleUrls: ['./googlemeet-report.component.scss']
})
export class GooglemeetReportComponent implements OnInit {
    @ViewChild('video') video: any;
    @ViewChild('screen') screen: any;
    dataRaw: any;
    noop: any;
    sessionDataSeconds: any = [];
    //   overallData: any = [
    //     {
    //         "timestamp": "Wed Aug 31 2022 06:41:54 GMT+0000 (Coordinated Universal Time)",
    //         "average_engagement": 80,
    //         "average_mood": 20,
    //         "segment": 1,
    //         "category": "Medium",
    //         "moodCategory": "Low"
    //     },
    //     {
    //         "timestamp": "Wed Aug 31 2022 06:41:59 GMT+0000 (Coordinated Universal Time)",
    //         "average_engagement": 77,
    //         "average_mood": 44,
    //         "segment": 2,
    //         "category": "Medium",
    //         "moodCategory": "Medium"
    //     },
    //     {
    //         "timestamp": "Wed Aug 31 2022 06:42:04 GMT+0000 (Coordinated Universal Time)",
    //         "average_engagement": 40,
    //         "average_mood": 20,
    //         "segment": 3,
    //         "category": "Low",
    //         "moodCategory": "Low"
    //     },
    //     {
    //         "timestamp": "Wed Aug 31 2022 06:42:09 GMT+0000 (Coordinated Universal Time)",
    //         "average_engagement": 40,
    //         "average_mood": 20,
    //         "segment": 4,
    //         "category": "Low",
    //         "moodCategory": "Low"
    //     },
    //     {
    //         "timestamp": "Wed Aug 31 2022 06:42:19 GMT+0000 (Coordinated Universal Time)",
    //         "average_engagement": 79,
    //         "average_mood": 27,
    //         "segment": 5,
    //         "category": "Medium",
    //         "moodCategory": "Low"
    //     },
    //     {
    //         "timestamp": "Wed Aug 31 2022 06:42:24 GMT+0000 (Coordinated Universal Time)",
    //         "average_engagement": 94,
    //         "average_mood": 43,
    //         "segment": 6,
    //         "category": "High",
    //         "moodCategory": "Medium"
    //     },
    //     {
    //         "timestamp": "Wed Aug 31 2022 06:42:29 GMT+0000 (Coordinated Universal Time)",
    //         "average_engagement": 100,
    //         "average_mood": 45,
    //         "segment": 7,
    //         "category": "High",
    //         "moodCategory": "Medium"
    //     },
    //     {
    //         "timestamp": "Wed Aug 31 2022 06:42:34 GMT+0000 (Coordinated Universal Time)",
    //         "average_engagement": 100,
    //         "average_mood": 45,
    //         "segment": 8,
    //         "category": "High",
    //         "moodCategory": "Medium"
    //     },
    //     {
    //         "timestamp": "Wed Aug 31 2022 06:42:39 GMT+0000 (Coordinated Universal Time)",
    //         "average_engagement": 100,
    //         "average_mood": 45,
    //         "segment": 9,
    //         "category": "High",
    //         "moodCategory": "Medium"
    //     },
    //     {
    //         "timestamp": "Wed Aug 31 2022 06:42:44 GMT+0000 (Coordinated Universal Time)",
    //         "average_engagement": 100,
    //         "average_mood": 43,
    //         "segment": 10,
    //         "category": "High",
    //         "moodCategory": "Medium"
    //     },
    //     {
    //         "timestamp": "Wed Aug 31 2022 06:42:49 GMT+0000 (Coordinated Universal Time)",
    //         "average_engagement": 89,
    //         "average_mood": 59,
    //         "segment": 11,
    //         "category": "High",
    //         "moodCategory": "Medium"
    //     },
    //     {
    //         "timestamp": "Wed Aug 31 2022 06:42:54 GMT+0000 (Coordinated Universal Time)",
    //         "average_engagement": 92,
    //         "average_mood": 41,
    //         "segment": 12,
    //         "category": "High",
    //         "moodCategory": "Medium"
    //     },
    //     {
    //         "timestamp": "Wed Aug 31 2022 06:42:59 GMT+0000 (Coordinated Universal Time)",
    //         "average_engagement": 88,
    //         "average_mood": 42,
    //         "segment": 13,
    //         "category": "High",
    //         "moodCategory": "Medium"
    //     },
    //     {
    //         "timestamp": "Wed Aug 31 2022 06:43:04 GMT+0000 (Coordinated Universal Time)",
    //         "average_engagement": 100,
    //         "average_mood": 47,
    //         "segment": 14,
    //         "category": "High",
    //         "moodCategory": "Medium"
    //     },
    //     {
    //         "timestamp": "Wed Aug 31 2022 06:43:09 GMT+0000 (Coordinated Universal Time)",
    //         "average_engagement": 100,
    //         "average_mood": 31,
    //         "segment": 15,
    //         "category": "High",
    //         "moodCategory": "Low"
    //     },
    //     {
    //         "timestamp": "Wed Aug 31 2022 06:43:14 GMT+0000 (Coordinated Universal Time)",
    //         "average_engagement": 100,
    //         "average_mood": 48,
    //         "segment": 16,
    //         "category": "High",
    //         "moodCategory": "Medium"
    //     },
    //     {
    //         "timestamp": "Wed Aug 31 2022 06:43:19 GMT+0000 (Coordinated Universal Time)",
    //         "average_engagement": 100,
    //         "average_mood": 43,
    //         "segment": 17,
    //         "category": "High",
    //         "moodCategory": "Medium"
    //     },
    //     {
    //         "timestamp": "Wed Aug 31 2022 06:43:24 GMT+0000 (Coordinated Universal Time)",
    //         "average_engagement": 100,
    //         "average_mood": 51,
    //         "segment": 18,
    //         "category": "High",
    //         "moodCategory": "Medium"
    //     },
    //     {
    //         "timestamp": "Wed Aug 31 2022 06:43:29 GMT+0000 (Coordinated Universal Time)",
    //         "average_engagement": 99,
    //         "average_mood": 41,
    //         "segment": 19,
    //         "category": "High",
    //         "moodCategory": "Medium"
    //     },
    //     {
    //         "timestamp": "Wed Aug 31 2022 06:43:34 GMT+0000 (Coordinated Universal Time)",
    //         "average_engagement": 100,
    //         "average_mood": 44,
    //         "segment": 20,
    //         "category": "High",
    //         "moodCategory": "Medium"
    //     },
    //     {
    //         "timestamp": "Wed Aug 31 2022 06:43:39 GMT+0000 (Coordinated Universal Time)",
    //         "average_engagement": 100,
    //         "average_mood": 45,
    //         "segment": 21,
    //         "category": "High",
    //         "moodCategory": "Medium"
    //     },
    //     {
    //         "timestamp": "Wed Aug 31 2022 06:43:44 GMT+0000 (Coordinated Universal Time)",
    //         "average_engagement": 100,
    //         "average_mood": 44,
    //         "segment": 22,
    //         "category": "High",
    //         "moodCategory": "Medium"
    //     },
    //     {
    //         "timestamp": "Wed Aug 31 2022 06:43:49 GMT+0000 (Coordinated Universal Time)",
    //         "average_engagement": 100,
    //         "average_mood": 46,
    //         "segment": 23,
    //         "category": "High",
    //         "moodCategory": "Medium"
    //     },
    //     {
    //         "timestamp": "Wed Aug 31 2022 06:43:54 GMT+0000 (Coordinated Universal Time)",
    //         "average_engagement": 100,
    //         "average_mood": 44,
    //         "segment": 24,
    //         "category": "High",
    //         "moodCategory": "Medium"
    //     },
    //     {
    //         "timestamp": "Wed Aug 31 2022 06:43:59 GMT+0000 (Coordinated Universal Time)",
    //         "average_engagement": 100,
    //         "average_mood": 41,
    //         "segment": 25,
    //         "category": "High",
    //         "moodCategory": "Medium"
    //     },
    //     {
    //         "timestamp": "Wed Aug 31 2022 06:44:04 GMT+0000 (Coordinated Universal Time)",
    //         "average_engagement": 100,
    //         "average_mood": 41,
    //         "segment": 26,
    //         "category": "High",
    //         "moodCategory": "Medium"
    //     },
    //     {
    //         "timestamp": "Wed Aug 31 2022 06:44:09 GMT+0000 (Coordinated Universal Time)",
    //         "average_engagement": 100,
    //         "average_mood": 43,
    //         "segment": 27,
    //         "category": "High",
    //         "moodCategory": "Medium"
    //     },
    //     {
    //         "timestamp": "Wed Aug 31 2022 06:44:14 GMT+0000 (Coordinated Universal Time)",
    //         "average_engagement": 100,
    //         "average_mood": 42,
    //         "segment": 28,
    //         "category": "High",
    //         "moodCategory": "Medium"
    //     },
    //     {
    //         "timestamp": "Wed Aug 31 2022 06:44:19 GMT+0000 (Coordinated Universal Time)",
    //         "average_engagement": 100,
    //         "average_mood": 44,
    //         "segment": 29,
    //         "category": "High",
    //         "moodCategory": "Medium"
    //     },
    //     {
    //         "timestamp": "Wed Aug 31 2022 06:44:24 GMT+0000 (Coordinated Universal Time)",
    //         "average_engagement": 95,
    //         "average_mood": 46,
    //         "segment": 30,
    //         "category": "High",
    //         "moodCategory": "Medium"
    //     },
    //     {
    //         "timestamp": "Wed Aug 31 2022 06:44:29 GMT+0000 (Coordinated Universal Time)",
    //         "average_engagement": 93,
    //         "average_mood": 51,
    //         "segment": 31,
    //         "category": "High",
    //         "moodCategory": "Medium"
    //     },
    //     {
    //         "timestamp": "Wed Aug 31 2022 06:44:34 GMT+0000 (Coordinated Universal Time)",
    //         "average_engagement": 92,
    //         "average_mood": 34,
    //         "segment": 32,
    //         "category": "High",
    //         "moodCategory": "Low"
    //     },
    //     {
    //         "timestamp": "Wed Aug 31 2022 06:44:39 GMT+0000 (Coordinated Universal Time)",
    //         "average_engagement": 91,
    //         "average_mood": 46,
    //         "segment": 33,
    //         "category": "High",
    //         "moodCategory": "Medium"
    //     },
    //     {
    //         "timestamp": "Wed Aug 31 2022 06:44:44 GMT+0000 (Coordinated Universal Time)",
    //         "average_engagement": 75,
    //         "average_mood": 20,
    //         "segment": 34,
    //         "category": "Medium",
    //         "moodCategory": "Low"
    //     },
    //     {
    //         "timestamp": "Wed Aug 31 2022 06:44:49 GMT+0000 (Coordinated Universal Time)",
    //         "average_engagement": 75,
    //         "average_mood": 20,
    //         "segment": 35,
    //         "category": "Medium",
    //         "moodCategory": "Low"
    //     },
    //     {
    //         "timestamp": "Wed Aug 31 2022 06:44:54 GMT+0000 (Coordinated Universal Time)",
    //         "average_engagement": 75,
    //         "average_mood": 20,
    //         "segment": 36,
    //         "category": "Medium",
    //         "moodCategory": "Low"
    //     },
    //     {
    //         "timestamp": "Wed Aug 31 2022 06:44:59 GMT+0000 (Coordinated Universal Time)",
    //         "average_engagement": 75,
    //         "average_mood": 20,
    //         "segment": 37,
    //         "category": "Medium",
    //         "moodCategory": "Low"
    //     },
    //     {
    //         "timestamp": "Wed Aug 31 2022 06:45:04 GMT+0000 (Coordinated Universal Time)",
    //         "average_engagement": 94,
    //         "average_mood": 51,
    //         "segment": 38,
    //         "category": "High",
    //         "moodCategory": "Medium"
    //     },
    //     {
    //         "timestamp": "Wed Aug 31 2022 06:45:09 GMT+0000 (Coordinated Universal Time)",
    //         "average_engagement": 75,
    //         "average_mood": 20,
    //         "segment": 39,
    //         "category": "Medium",
    //         "moodCategory": "Low"
    //     },
    //     {
    //         "timestamp": "Wed Aug 31 2022 06:45:14 GMT+0000 (Coordinated Universal Time)",
    //         "average_engagement": 84,
    //         "average_mood": 42,
    //         "segment": 40,
    //         "category": "High",
    //         "moodCategory": "Medium"
    //     },
    //     {
    //         "timestamp": "Wed Aug 31 2022 06:45:19 GMT+0000 (Coordinated Universal Time)",
    //         "average_engagement": 83,
    //         "average_mood": 34,
    //         "segment": 41,
    //         "category": "High",
    //         "moodCategory": "Low"
    //     },
    //     {
    //         "timestamp": "Wed Aug 31 2022 06:45:24 GMT+0000 (Coordinated Universal Time)",
    //         "average_engagement": 82,
    //         "average_mood": 49,
    //         "segment": 42,
    //         "category": "High",
    //         "moodCategory": "Medium"
    //     },
    //     {
    //         "timestamp": "Wed Aug 31 2022 06:45:29 GMT+0000 (Coordinated Universal Time)",
    //         "average_engagement": 75,
    //         "average_mood": 20,
    //         "segment": 43,
    //         "category": "Medium",
    //         "moodCategory": "Low"
    //     },
    //     {
    //         "timestamp": "Wed Aug 31 2022 06:45:34 GMT+0000 (Coordinated Universal Time)",
    //         "average_engagement": 75,
    //         "average_mood": 20,
    //         "segment": 44,
    //         "category": "Medium",
    //         "moodCategory": "Low"
    //     },
    //     {
    //         "timestamp": "Wed Aug 31 2022 06:45:39 GMT+0000 (Coordinated Universal Time)",
    //         "average_engagement": 75,
    //         "average_mood": 20,
    //         "segment": 45,
    //         "category": "Medium",
    //         "moodCategory": "Low"
    //     },
    //     {
    //         "timestamp": "Wed Aug 31 2022 06:45:44 GMT+0000 (Coordinated Universal Time)",
    //         "average_engagement": 75,
    //         "average_mood": 20,
    //         "segment": 46,
    //         "category": "Medium",
    //         "moodCategory": "Low"
    //     },
    //     {
    //         "timestamp": "Wed Aug 31 2022 06:45:49 GMT+0000 (Coordinated Universal Time)",
    //         "average_engagement": 100,
    //         "average_mood": 33,
    //         "segment": 47,
    //         "category": "High",
    //         "moodCategory": "Low"
    //     },
    //     {
    //         "timestamp": "Wed Aug 31 2022 06:45:54 GMT+0000 (Coordinated Universal Time)",
    //         "average_engagement": 100,
    //         "average_mood": 39,
    //         "segment": 48,
    //         "category": "High",
    //         "moodCategory": "Low"
    //     },
    //     {
    //         "timestamp": "Wed Aug 31 2022 06:45:59 GMT+0000 (Coordinated Universal Time)",
    //         "average_engagement": 100,
    //         "average_mood": 38,
    //         "segment": 49,
    //         "category": "High",
    //         "moodCategory": "Low"
    //     },
    //     {
    //         "timestamp": "Wed Aug 31 2022 06:46:04 GMT+0000 (Coordinated Universal Time)",
    //         "average_engagement": 100,
    //         "average_mood": 33,
    //         "segment": 50,
    //         "category": "High",
    //         "moodCategory": "Low"
    //     },
    //     {
    //         "timestamp": "Wed Aug 31 2022 06:46:09 GMT+0000 (Coordinated Universal Time)",
    //         "average_engagement": 100,
    //         "average_mood": 32,
    //         "segment": 51,
    //         "category": "High",
    //         "moodCategory": "Low"
    //     },
    //     {
    //         "timestamp": "Wed Aug 31 2022 06:46:14 GMT+0000 (Coordinated Universal Time)",
    //         "average_engagement": 100,
    //         "average_mood": 27,
    //         "segment": 52,
    //         "category": "High",
    //         "moodCategory": "Low"
    //     },
    //     {
    //         "timestamp": "Wed Aug 31 2022 06:46:19 GMT+0000 (Coordinated Universal Time)",
    //         "average_engagement": 94,
    //         "average_mood": 40,
    //         "segment": 53,
    //         "category": "High",
    //         "moodCategory": "Low"
    //     },
    //     {
    //         "timestamp": "Wed Aug 31 2022 06:46:24 GMT+0000 (Coordinated Universal Time)",
    //         "average_engagement": 100,
    //         "average_mood": 44,
    //         "segment": 54,
    //         "category": "High",
    //         "moodCategory": "Medium"
    //     },
    //     {
    //         "timestamp": "Wed Aug 31 2022 06:46:29 GMT+0000 (Coordinated Universal Time)",
    //         "average_engagement": 100,
    //         "average_mood": 44,
    //         "segment": 55,
    //         "category": "High",
    //         "moodCategory": "Medium"
    //     },
    //     {
    //         "timestamp": "Wed Aug 31 2022 06:46:34 GMT+0000 (Coordinated Universal Time)",
    //         "average_engagement": 100,
    //         "average_mood": 45,
    //         "segment": 56,
    //         "category": "High",
    //         "moodCategory": "Medium"
    //     },
    //     {
    //         "timestamp": "Wed Aug 31 2022 06:46:39 GMT+0000 (Coordinated Universal Time)",
    //         "average_engagement": 100,
    //         "average_mood": 40,
    //         "segment": 57,
    //         "category": "High",
    //         "moodCategory": "Low"
    //     },
    //     {
    //         "timestamp": "Wed Aug 31 2022 06:46:44 GMT+0000 (Coordinated Universal Time)",
    //         "average_engagement": 100,
    //         "average_mood": 42,
    //         "segment": 58,
    //         "category": "High",
    //         "moodCategory": "Medium"
    //     },
    //     {
    //         "timestamp": "Wed Aug 31 2022 06:46:49 GMT+0000 (Coordinated Universal Time)",
    //         "average_engagement": 88,
    //         "average_mood": 31,
    //         "segment": 59,
    //         "category": "High",
    //         "moodCategory": "Low"
    //     },
    //     {
    //         "timestamp": "Wed Aug 31 2022 06:46:54 GMT+0000 (Coordinated Universal Time)",
    //         "average_engagement": 100,
    //         "average_mood": 43,
    //         "segment": 60,
    //         "category": "High",
    //         "moodCategory": "Medium"
    //     },
    //     {
    //         "timestamp": "Wed Aug 31 2022 06:46:59 GMT+0000 (Coordinated Universal Time)",
    //         "average_engagement": 100,
    //         "average_mood": 54,
    //         "segment": 61,
    //         "category": "High",
    //         "moodCategory": "Medium"
    //     },
    //     {
    //         "timestamp": "Wed Aug 31 2022 06:47:04 GMT+0000 (Coordinated Universal Time)",
    //         "average_engagement": 92,
    //         "average_mood": 29,
    //         "segment": 62,
    //         "category": "High",
    //         "moodCategory": "Low"
    //     },
    //     {
    //         "timestamp": "Wed Aug 31 2022 06:47:09 GMT+0000 (Coordinated Universal Time)",
    //         "average_engagement": 100,
    //         "average_mood": 44,
    //         "segment": 63,
    //         "category": "High",
    //         "moodCategory": "Medium"
    //     }
    // ];
    overallData: any = []
    constructor(private http: HttpClient, private utility: UtilityService, public dialogRef: MatDialogRef<GooglemeetReportComponent>) { }

    ngOnInit(): void {
        // debugger
        // const httpOptions = {
        //     headers: new HttpHeaders({
        //       'Content-Type':  'application/json',
        //       Authorization: 'my-auth-token'
        //     })
        //   };
        // console.log('@@@2', httpOptions);
        let index = 1;
        this.http.get('https://www.monetlive.com/many/api/facedataV2?uuid=9a990b4097f34728b698c526b08ec16b').subscribe((response: any) => {
            //   console.log('%c facedataV2', 'background-color: aqua', response);
            this.overallData = response.data.map((el: any) => {
                el.segment = index++;
                el.category = this.utility.setCategory(el.engagement);
                el.moodCategory = this.utility.setCategory(el.mood);
                return el;
            });
            hideloading();
            //   console.log('%c facedataV2', 'background-color: purple', this.overallData);
            function hideloading() {
                document.getElementById('loading')
                    .style.display = 'none';
            }
        });

        this.http.get('https://www.monetlive.com/many/api/report?id=4b85f0130d334e3fb7d268bac8d30df8').subscribe((response: any) => {
            console.log('%c &&&&&&', 'background-color: red', response);
            this.dataRaw = response.response;

        })
    }

    toggleScreenShare(): void {
        setTimeout(() => {
          if (this.screen !== undefined) {
            this.screen.nativeElement.pause();
          }
        }, 500);
      }

}
