import { HttpClient } from '@angular/common/http';
import { Component, Input, NgZone, OnInit, SimpleChanges } from '@angular/core';
import { BehaviourSubjectsService } from 'src/app/services/behaviour-subjects.service';
import { ApiService } from '../../shared/services/api.service';
import { UtilityService } from '../../shared/services/utility.service';
import * as am4core from '@amcharts/amcharts4/core';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import am4themes_dark from '@amcharts/amcharts4/themes/dark';
import * as am4charts from '@amcharts/amcharts4/charts';

@Component({
  selector: 'app-engagement-graph',
  templateUrl: './engagement-graph.component.html',
  styleUrls: ['./engagement-graph.component.scss']
})
export class EngagementGraphComponent implements OnInit {
  sessionDataSeconds: any = [];
  @Input() overallData: any;
//   overallData: any = [
//     {
//         "timestamp": "Wed Aug 31 2022 06:41:54 GMT+0000 (Coordinated Universal Time)",
//         "average_engagement": 80,
//         "average_mood": 20,
//         "segment": 1,
//         "category": "Medium",
//         "moodCategory": "Low"
//     },
//     {
//         "timestamp": "Wed Aug 31 2022 06:41:59 GMT+0000 (Coordinated Universal Time)",
//         "average_engagement": 77,
//         "average_mood": 44,
//         "segment": 2,
//         "category": "Medium",
//         "moodCategory": "Medium"
//     },
//     {
//         "timestamp": "Wed Aug 31 2022 06:42:04 GMT+0000 (Coordinated Universal Time)",
//         "average_engagement": 40,
//         "average_mood": 20,
//         "segment": 3,
//         "category": "Low",
//         "moodCategory": "Low"
//     },
//     {
//         "timestamp": "Wed Aug 31 2022 06:42:09 GMT+0000 (Coordinated Universal Time)",
//         "average_engagement": 40,
//         "average_mood": 20,
//         "segment": 4,
//         "category": "Low",
//         "moodCategory": "Low"
//     },
//     {
//         "timestamp": "Wed Aug 31 2022 06:42:19 GMT+0000 (Coordinated Universal Time)",
//         "average_engagement": 79,
//         "average_mood": 27,
//         "segment": 5,
//         "category": "Medium",
//         "moodCategory": "Low"
//     },
//     {
//         "timestamp": "Wed Aug 31 2022 06:42:24 GMT+0000 (Coordinated Universal Time)",
//         "average_engagement": 94,
//         "average_mood": 43,
//         "segment": 6,
//         "category": "High",
//         "moodCategory": "Medium"
//     },
//     {
//         "timestamp": "Wed Aug 31 2022 06:42:29 GMT+0000 (Coordinated Universal Time)",
//         "average_engagement": 100,
//         "average_mood": 45,
//         "segment": 7,
//         "category": "High",
//         "moodCategory": "Medium"
//     },
//     {
//         "timestamp": "Wed Aug 31 2022 06:42:34 GMT+0000 (Coordinated Universal Time)",
//         "average_engagement": 100,
//         "average_mood": 45,
//         "segment": 8,
//         "category": "High",
//         "moodCategory": "Medium"
//     },
//     {
//         "timestamp": "Wed Aug 31 2022 06:42:39 GMT+0000 (Coordinated Universal Time)",
//         "average_engagement": 100,
//         "average_mood": 45,
//         "segment": 9,
//         "category": "High",
//         "moodCategory": "Medium"
//     },
//     {
//         "timestamp": "Wed Aug 31 2022 06:42:44 GMT+0000 (Coordinated Universal Time)",
//         "average_engagement": 100,
//         "average_mood": 43,
//         "segment": 10,
//         "category": "High",
//         "moodCategory": "Medium"
//     },
//     {
//         "timestamp": "Wed Aug 31 2022 06:42:49 GMT+0000 (Coordinated Universal Time)",
//         "average_engagement": 89,
//         "average_mood": 59,
//         "segment": 11,
//         "category": "High",
//         "moodCategory": "Medium"
//     },
//     {
//         "timestamp": "Wed Aug 31 2022 06:42:54 GMT+0000 (Coordinated Universal Time)",
//         "average_engagement": 92,
//         "average_mood": 41,
//         "segment": 12,
//         "category": "High",
//         "moodCategory": "Medium"
//     },
//     {
//         "timestamp": "Wed Aug 31 2022 06:42:59 GMT+0000 (Coordinated Universal Time)",
//         "average_engagement": 88,
//         "average_mood": 42,
//         "segment": 13,
//         "category": "High",
//         "moodCategory": "Medium"
//     },
//     {
//         "timestamp": "Wed Aug 31 2022 06:43:04 GMT+0000 (Coordinated Universal Time)",
//         "average_engagement": 100,
//         "average_mood": 47,
//         "segment": 14,
//         "category": "High",
//         "moodCategory": "Medium"
//     },
//     {
//         "timestamp": "Wed Aug 31 2022 06:43:09 GMT+0000 (Coordinated Universal Time)",
//         "average_engagement": 100,
//         "average_mood": 31,
//         "segment": 15,
//         "category": "High",
//         "moodCategory": "Low"
//     },
//     {
//         "timestamp": "Wed Aug 31 2022 06:43:14 GMT+0000 (Coordinated Universal Time)",
//         "average_engagement": 100,
//         "average_mood": 48,
//         "segment": 16,
//         "category": "High",
//         "moodCategory": "Medium"
//     },
//     {
//         "timestamp": "Wed Aug 31 2022 06:43:19 GMT+0000 (Coordinated Universal Time)",
//         "average_engagement": 100,
//         "average_mood": 43,
//         "segment": 17,
//         "category": "High",
//         "moodCategory": "Medium"
//     },
//     {
//         "timestamp": "Wed Aug 31 2022 06:43:24 GMT+0000 (Coordinated Universal Time)",
//         "average_engagement": 100,
//         "average_mood": 51,
//         "segment": 18,
//         "category": "High",
//         "moodCategory": "Medium"
//     },
//     {
//         "timestamp": "Wed Aug 31 2022 06:43:29 GMT+0000 (Coordinated Universal Time)",
//         "average_engagement": 99,
//         "average_mood": 41,
//         "segment": 19,
//         "category": "High",
//         "moodCategory": "Medium"
//     },
//     {
//         "timestamp": "Wed Aug 31 2022 06:43:34 GMT+0000 (Coordinated Universal Time)",
//         "average_engagement": 100,
//         "average_mood": 44,
//         "segment": 20,
//         "category": "High",
//         "moodCategory": "Medium"
//     },
//     {
//         "timestamp": "Wed Aug 31 2022 06:43:39 GMT+0000 (Coordinated Universal Time)",
//         "average_engagement": 100,
//         "average_mood": 45,
//         "segment": 21,
//         "category": "High",
//         "moodCategory": "Medium"
//     },
//     {
//         "timestamp": "Wed Aug 31 2022 06:43:44 GMT+0000 (Coordinated Universal Time)",
//         "average_engagement": 100,
//         "average_mood": 44,
//         "segment": 22,
//         "category": "High",
//         "moodCategory": "Medium"
//     },
//     {
//         "timestamp": "Wed Aug 31 2022 06:43:49 GMT+0000 (Coordinated Universal Time)",
//         "average_engagement": 100,
//         "average_mood": 46,
//         "segment": 23,
//         "category": "High",
//         "moodCategory": "Medium"
//     },
//     {
//         "timestamp": "Wed Aug 31 2022 06:43:54 GMT+0000 (Coordinated Universal Time)",
//         "average_engagement": 100,
//         "average_mood": 44,
//         "segment": 24,
//         "category": "High",
//         "moodCategory": "Medium"
//     },
//     {
//         "timestamp": "Wed Aug 31 2022 06:43:59 GMT+0000 (Coordinated Universal Time)",
//         "average_engagement": 100,
//         "average_mood": 41,
//         "segment": 25,
//         "category": "High",
//         "moodCategory": "Medium"
//     },
//     {
//         "timestamp": "Wed Aug 31 2022 06:44:04 GMT+0000 (Coordinated Universal Time)",
//         "average_engagement": 100,
//         "average_mood": 41,
//         "segment": 26,
//         "category": "High",
//         "moodCategory": "Medium"
//     },
//     {
//         "timestamp": "Wed Aug 31 2022 06:44:09 GMT+0000 (Coordinated Universal Time)",
//         "average_engagement": 100,
//         "average_mood": 43,
//         "segment": 27,
//         "category": "High",
//         "moodCategory": "Medium"
//     },
//     {
//         "timestamp": "Wed Aug 31 2022 06:44:14 GMT+0000 (Coordinated Universal Time)",
//         "average_engagement": 100,
//         "average_mood": 42,
//         "segment": 28,
//         "category": "High",
//         "moodCategory": "Medium"
//     },
//     {
//         "timestamp": "Wed Aug 31 2022 06:44:19 GMT+0000 (Coordinated Universal Time)",
//         "average_engagement": 100,
//         "average_mood": 44,
//         "segment": 29,
//         "category": "High",
//         "moodCategory": "Medium"
//     },
//     {
//         "timestamp": "Wed Aug 31 2022 06:44:24 GMT+0000 (Coordinated Universal Time)",
//         "average_engagement": 95,
//         "average_mood": 46,
//         "segment": 30,
//         "category": "High",
//         "moodCategory": "Medium"
//     },
//     {
//         "timestamp": "Wed Aug 31 2022 06:44:29 GMT+0000 (Coordinated Universal Time)",
//         "average_engagement": 93,
//         "average_mood": 51,
//         "segment": 31,
//         "category": "High",
//         "moodCategory": "Medium"
//     },
//     {
//         "timestamp": "Wed Aug 31 2022 06:44:34 GMT+0000 (Coordinated Universal Time)",
//         "average_engagement": 92,
//         "average_mood": 34,
//         "segment": 32,
//         "category": "High",
//         "moodCategory": "Low"
//     },
//     {
//         "timestamp": "Wed Aug 31 2022 06:44:39 GMT+0000 (Coordinated Universal Time)",
//         "average_engagement": 91,
//         "average_mood": 46,
//         "segment": 33,
//         "category": "High",
//         "moodCategory": "Medium"
//     },
//     {
//         "timestamp": "Wed Aug 31 2022 06:44:44 GMT+0000 (Coordinated Universal Time)",
//         "average_engagement": 75,
//         "average_mood": 20,
//         "segment": 34,
//         "category": "Medium",
//         "moodCategory": "Low"
//     },
//     {
//         "timestamp": "Wed Aug 31 2022 06:44:49 GMT+0000 (Coordinated Universal Time)",
//         "average_engagement": 75,
//         "average_mood": 20,
//         "segment": 35,
//         "category": "Medium",
//         "moodCategory": "Low"
//     },
//     {
//         "timestamp": "Wed Aug 31 2022 06:44:54 GMT+0000 (Coordinated Universal Time)",
//         "average_engagement": 75,
//         "average_mood": 20,
//         "segment": 36,
//         "category": "Medium",
//         "moodCategory": "Low"
//     },
//     {
//         "timestamp": "Wed Aug 31 2022 06:44:59 GMT+0000 (Coordinated Universal Time)",
//         "average_engagement": 75,
//         "average_mood": 20,
//         "segment": 37,
//         "category": "Medium",
//         "moodCategory": "Low"
//     },
//     {
//         "timestamp": "Wed Aug 31 2022 06:45:04 GMT+0000 (Coordinated Universal Time)",
//         "average_engagement": 94,
//         "average_mood": 51,
//         "segment": 38,
//         "category": "High",
//         "moodCategory": "Medium"
//     },
//     {
//         "timestamp": "Wed Aug 31 2022 06:45:09 GMT+0000 (Coordinated Universal Time)",
//         "average_engagement": 75,
//         "average_mood": 20,
//         "segment": 39,
//         "category": "Medium",
//         "moodCategory": "Low"
//     },
//     {
//         "timestamp": "Wed Aug 31 2022 06:45:14 GMT+0000 (Coordinated Universal Time)",
//         "average_engagement": 84,
//         "average_mood": 42,
//         "segment": 40,
//         "category": "High",
//         "moodCategory": "Medium"
//     },
//     {
//         "timestamp": "Wed Aug 31 2022 06:45:19 GMT+0000 (Coordinated Universal Time)",
//         "average_engagement": 83,
//         "average_mood": 34,
//         "segment": 41,
//         "category": "High",
//         "moodCategory": "Low"
//     },
//     {
//         "timestamp": "Wed Aug 31 2022 06:45:24 GMT+0000 (Coordinated Universal Time)",
//         "average_engagement": 82,
//         "average_mood": 49,
//         "segment": 42,
//         "category": "High",
//         "moodCategory": "Medium"
//     },
//     {
//         "timestamp": "Wed Aug 31 2022 06:45:29 GMT+0000 (Coordinated Universal Time)",
//         "average_engagement": 75,
//         "average_mood": 20,
//         "segment": 43,
//         "category": "Medium",
//         "moodCategory": "Low"
//     },
//     {
//         "timestamp": "Wed Aug 31 2022 06:45:34 GMT+0000 (Coordinated Universal Time)",
//         "average_engagement": 75,
//         "average_mood": 20,
//         "segment": 44,
//         "category": "Medium",
//         "moodCategory": "Low"
//     },
//     {
//         "timestamp": "Wed Aug 31 2022 06:45:39 GMT+0000 (Coordinated Universal Time)",
//         "average_engagement": 75,
//         "average_mood": 20,
//         "segment": 45,
//         "category": "Medium",
//         "moodCategory": "Low"
//     },
//     {
//         "timestamp": "Wed Aug 31 2022 06:45:44 GMT+0000 (Coordinated Universal Time)",
//         "average_engagement": 75,
//         "average_mood": 20,
//         "segment": 46,
//         "category": "Medium",
//         "moodCategory": "Low"
//     },
//     {
//         "timestamp": "Wed Aug 31 2022 06:45:49 GMT+0000 (Coordinated Universal Time)",
//         "average_engagement": 100,
//         "average_mood": 33,
//         "segment": 47,
//         "category": "High",
//         "moodCategory": "Low"
//     },
//     {
//         "timestamp": "Wed Aug 31 2022 06:45:54 GMT+0000 (Coordinated Universal Time)",
//         "average_engagement": 100,
//         "average_mood": 39,
//         "segment": 48,
//         "category": "High",
//         "moodCategory": "Low"
//     },
//     {
//         "timestamp": "Wed Aug 31 2022 06:45:59 GMT+0000 (Coordinated Universal Time)",
//         "average_engagement": 100,
//         "average_mood": 38,
//         "segment": 49,
//         "category": "High",
//         "moodCategory": "Low"
//     },
//     {
//         "timestamp": "Wed Aug 31 2022 06:46:04 GMT+0000 (Coordinated Universal Time)",
//         "average_engagement": 100,
//         "average_mood": 33,
//         "segment": 50,
//         "category": "High",
//         "moodCategory": "Low"
//     },
//     {
//         "timestamp": "Wed Aug 31 2022 06:46:09 GMT+0000 (Coordinated Universal Time)",
//         "average_engagement": 100,
//         "average_mood": 32,
//         "segment": 51,
//         "category": "High",
//         "moodCategory": "Low"
//     },
//     {
//         "timestamp": "Wed Aug 31 2022 06:46:14 GMT+0000 (Coordinated Universal Time)",
//         "average_engagement": 100,
//         "average_mood": 27,
//         "segment": 52,
//         "category": "High",
//         "moodCategory": "Low"
//     },
//     {
//         "timestamp": "Wed Aug 31 2022 06:46:19 GMT+0000 (Coordinated Universal Time)",
//         "average_engagement": 94,
//         "average_mood": 40,
//         "segment": 53,
//         "category": "High",
//         "moodCategory": "Low"
//     },
//     {
//         "timestamp": "Wed Aug 31 2022 06:46:24 GMT+0000 (Coordinated Universal Time)",
//         "average_engagement": 100,
//         "average_mood": 44,
//         "segment": 54,
//         "category": "High",
//         "moodCategory": "Medium"
//     },
//     {
//         "timestamp": "Wed Aug 31 2022 06:46:29 GMT+0000 (Coordinated Universal Time)",
//         "average_engagement": 100,
//         "average_mood": 44,
//         "segment": 55,
//         "category": "High",
//         "moodCategory": "Medium"
//     },
//     {
//         "timestamp": "Wed Aug 31 2022 06:46:34 GMT+0000 (Coordinated Universal Time)",
//         "average_engagement": 100,
//         "average_mood": 45,
//         "segment": 56,
//         "category": "High",
//         "moodCategory": "Medium"
//     },
//     {
//         "timestamp": "Wed Aug 31 2022 06:46:39 GMT+0000 (Coordinated Universal Time)",
//         "average_engagement": 100,
//         "average_mood": 40,
//         "segment": 57,
//         "category": "High",
//         "moodCategory": "Low"
//     },
//     {
//         "timestamp": "Wed Aug 31 2022 06:46:44 GMT+0000 (Coordinated Universal Time)",
//         "average_engagement": 100,
//         "average_mood": 42,
//         "segment": 58,
//         "category": "High",
//         "moodCategory": "Medium"
//     },
//     {
//         "timestamp": "Wed Aug 31 2022 06:46:49 GMT+0000 (Coordinated Universal Time)",
//         "average_engagement": 88,
//         "average_mood": 31,
//         "segment": 59,
//         "category": "High",
//         "moodCategory": "Low"
//     },
//     {
//         "timestamp": "Wed Aug 31 2022 06:46:54 GMT+0000 (Coordinated Universal Time)",
//         "average_engagement": 100,
//         "average_mood": 43,
//         "segment": 60,
//         "category": "High",
//         "moodCategory": "Medium"
//     },
//     {
//         "timestamp": "Wed Aug 31 2022 06:46:59 GMT+0000 (Coordinated Universal Time)",
//         "average_engagement": 100,
//         "average_mood": 54,
//         "segment": 61,
//         "category": "High",
//         "moodCategory": "Medium"
//     },
//     {
//         "timestamp": "Wed Aug 31 2022 06:47:04 GMT+0000 (Coordinated Universal Time)",
//         "average_engagement": 92,
//         "average_mood": 29,
//         "segment": 62,
//         "category": "High",
//         "moodCategory": "Low"
//     },
//     {
//         "timestamp": "Wed Aug 31 2022 06:47:09 GMT+0000 (Coordinated Universal Time)",
//         "average_engagement": 100,
//         "average_mood": 44,
//         "segment": 63,
//         "category": "High",
//         "moodCategory": "Medium"
//     }
// ];
  chart: any;
  engOrMood: any = 0;
  yAxisLabels: any = [{ value: 40, text: 'Low' }, { value: 80, text: 'Medium' }, { value: 100, text: 'High' }];
  constructor(private zone: NgZone, private bhvSub: BehaviourSubjectsService) { }

  ngOnInit(): void {
    this.bhvSub.engOrMood$.subscribe((data: any) => {
      this.engOrMood = data;
      if (this.chart) {
        this.chart.dispose();
        setTimeout(() => {
          this.createChart();
        }, 1000);

      }
      else {
        this.createChart();
      }
    });
  }
  createChart(): void {
    // @ts-ignore
    am4core.useTheme(am4themes_animated);
    am4core.useTheme(am4themes_dark);
    // Themes end

    // Create chart instance
    this.chart = am4core.create('engagementChart', am4charts.XYChart);

    // Add data
    this.chart.data = this.overallData;
    // Create axes
    const dateAxis = this.chart.xAxes.push(new am4charts.CategoryAxis());
    dateAxis.dataFields.category = 'segment';
    dateAxis.renderer.grid.template.disabled = true;
    dateAxis.cursorTooltipEnabled = false;

    const valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.labels.template.disabled = true;
    valueAxis.cursorTooltipEnabled = false;
    valueAxis.renderer.line.strokeOpacity = 0;
    valueAxis.min = 0;
    valueAxis.max = 110;
    valueAxis.renderer.grid.template.disabled = true;


    // For categories on y-axis
    const yAxis = this.chart.yAxes.push(new am4charts.CategoryAxis());
    // yAxis.dataFields.category = this.engOrMood ? 'moodCategory' : 'category';
    yAxis.dataFields.category = 'category';
    yAxis.cursorTooltipEnabled = false;
    yAxis.renderer.labels.template.disabled = true;
    yAxis.renderer.grid.template.disabled = true;


    // Create series
    const series = this.chart.series.push(new am4charts.LineSeries());
    series.tooltipText = 'Engagement : {engagement.formatNumber(".##")}';
    series.fillOpacity = 1;
    const fillModifier = new am4core.LinearGradientModifier();
    fillModifier.opacities = [0.7, 0];
    fillModifier.offsets = [0, 1];
    fillModifier.gradient.rotation = 90;
    series.segments.template.fillModifier = fillModifier;
    const { dataFields, tooltip } = series;
    // dataFields.valueY = this.engOrMood ? 'mood' : 'engagement';
    dataFields.valueY = 'engagement';
    series.hidden = false;
    dataFields.categoryX = 'segment';
    // series.tooltipText = '{value}';
    series.strokeWidth = 2;
    series.minBulletDistance = 15;

    // Range Axis
    function createRangeY(value: any, text: any): void {
      const range = valueAxis.axisRanges.create();
      range.value = value;
      range.label.text = text;

    }
    for (let i = 0; i < 3; i++) {
      if (this.yAxisLabels) {
        createRangeY(this.yAxisLabels[i].value, this.yAxisLabels[i].text);
      }
    }

    // Drop-shaped tooltips
    tooltip.background.cornerRadius = 20;
    tooltip.background.strokeOpacity = 0;
    tooltip.pointerOrientation = 'vertical';
    tooltip.label.minWidth = 20;
    tooltip.label.minHeight = 20;
    tooltip.label.textAlign = 'middle';
    tooltip.label.textValign = 'middle';

    // Make bullets grow on hover
    const bullet = series.bullets.push(new am4charts.CircleBullet());
    bullet.circle.strokeWidth = 2;
    bullet.circle.radius = 4;
    bullet.circle.fill = am4core.color('#fff');

    const bullethover = bullet.states.create('hover');
    bullethover.properties.scale = 1.3;
    this.chart.cursor = new am4charts.XYCursor();
  }
  ngAfterViewInit(): void {
    // console.warn(this.chart.isDisposed());
    if (this.chart) {
      this.chart.dispose();
      setTimeout(() => {
        this.createChart();
      }, 1000);

    }
    else {
      this.createChart();
    }
  }

  ngOnChanges(sc: SimpleChanges): void {
    if (sc?.overallData) {
      // console.warn(sc);
      if (this.chart) {
        this.chart.dispose();
        setTimeout(() => {
          this.createChart();
        }, 1000);

      }
      else {
        this.createChart();
      }
    }
  }

  ngOnDestroy(): void {
    try {
      this.zone.runOutsideAngular(() => {
        if (this.chart) {
          this.chart.dispose();
          delete this.chart;
        }
      });
    } catch (e) {
      console.log(e);
    }
  }

}
