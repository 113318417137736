<div fxFlex="95" style="height: 100vh; margin: auto" fxLayoutAlign="center center">
<div fxLayout="column" fxLayoutGap="30px" termsStyle>
  <ng-container *ngFor="let t of terms">
    <div fxFlex="100" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
      <span>{{t.name}}</span>
      <span>{{t.content}}</span>
    </div>
  </ng-container>
</div>
</div>
