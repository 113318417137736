<div class="d-flex justify-content-center">
    <div class="spinner-border" role="status" style=" overflow: hidden;">
        <span class="sr-only" id="loading"></span>
    </div>
</div>

<div class="container">
    <button mat-icon-button (click)="dialogRef.close(true)"
        style=" color: #fff; font-size: 20px; top: 2%; right: 2%; position: absolute; transform: translate(50%, -50%);">
        <mat-icon>close</mat-icon>
    </button>
    <div class="logo">
        <img src="/assets/MonetLiveWhite.png" width="15%" alt="monet" pointer>
    </div>

    <div class="report">

        <div class="engagement">
            <h1>Per second engagement</h1>
            <div id="chartdiv" style="width: 95%; height: 450px; background-color: #242424;">
                <app-engagement-graph [overallData]="overallData"></app-engagement-graph>
            </div>
        </div>
        <div class="mood">
            <h1>Per second mood</h1>
            <div id="chartdiv" style="width: 95%; height: 450px; background-color: #242424;">
                <app-mood-graph [overallData]="overallData"></app-mood-graph>
            </div>
        </div>
    </div>
    <div fxLayout="column" fxFill fxLayoutGap="14px" padding>
        <div fxLayout="row" fxFlex fxLayoutAlign="space-around center" m-t0 m-b0>
            <div fxLayout="column" fxLayoutAlign="center center" class='parentVideo' [ngStyle.lt-xl]="{height:'60%'}"
                [ngStyle]="{height:'90%'}">
                <ng-container>
                    <div id="vidPlayPause" fxFill fxLayout="row" fxLayoutAlign="center center"
                        [ngClass]="[video.paused? 'display': 'child']">
                        <mat-icon
                        (click)=" video.paused ? video.play() : video.pause(); video.paused ? toggleScreenShare() : noop" 
                        style="cursor: pointer; color: grey; font-size: 50px;">
                        {{video.paused ? 'play_circle_outline' : 'pause_circle_outline'}}
                      </mat-icon>
                    </div>
                    <video id="video" muted #video border>
                        <source id='lineChartVideo' src ="https://www.monetlive.com/data/4b85f0130d334e3fb7d268bac8d30df8-final-webcam.webm" type="video/webm">
                        <!-- <source id='liveLineChartVideo' [src]="'https://' + dataRaw.audio_path?.link" type="audio/opus"> -->
                    </video>
                </ng-container>
            </div>
        </div>
    </div>
</div>
