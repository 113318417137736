import { Injectable } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { MatSnackBar } from "@angular/material/snack-bar";
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private router: Router,private _ac:ActivatedRoute,private http:HttpClient) { }

  loggedIn(): any {
    this._ac.queryParams.subscribe(async (param:any)=>{
      if(param.id_token){
       let result = await this.loginFunc(param.id_token,'auth/google');
       return true;
      }
      else{
        if (JSON.parse(localStorage.getItem('userDetails') || '').token) {
          // console.log('Token present');
        }
        else {
          console.log('token unavailable');
          this.router.navigate(['/auth']);
        }
        return !!JSON.parse(localStorage.getItem('userDetails') || '').token;
      }
    })
  }
  loginFunc(data: any, endpoint: string): any {
    this.http.post(`https://www.monetlive.com/many/api/${endpoint}`, data).subscribe(
      (next: any) => {
        if (!next.error) {
          localStorage.setItem('userDetails', JSON.stringify(next.user));
          this.tokenVerify(next);
        } else {
          console.log("error in login api");
          
        }
      });
  }

  tokenVerify(next: any): void {
    // console.log('Login Successful :', next);
    if (next && next?.user?.plan) {
      this.http.get(`https://www.monetlive.com/many/api/getPlan?planId=${next.user?.plan.id}`).subscribe((res: any) => {
        localStorage.setItem('userPlanDetails', JSON.stringify(res?.plan));
      });
    }
    else {
      console.warn('Plan Object missing in response');
    }
  }
}


@Injectable({
  providedIn: 'root'
})
export class IsLoggedIn {
  constructor(
    private router: Router, private snackBar: MatSnackBar, private au: AuthService) {
  }
  resolve(): any {
    if (localStorage.getItem('userDetails') && JSON.parse(localStorage.getItem('userDetails' || '{}') || '') && JSON.parse(localStorage.getItem('userDetails' || '{}') || '').token) {
      if (!((location.href.search('/report/') !== -1 || location.href.search('/teacher/') !== -1))) {
        this.router.navigate(['profile/dashboard/']);
      }
    }
    else {
      console.log('Token not found');
      this.snackBar.open('User Not Logged IN', '', {
        duration: 3000,
        horizontalPosition: 'left',
        verticalPosition: 'bottom',
        panelClass: ['custom-snackbar']
      });
      this.router.navigate(['/auth']);
    }
    return !!JSON.parse(localStorage.getItem('userDetails') || '').token;
  }
}
